.search-results {
    position: absolute;
    top: 55px;
    background-color: white;
    border-radius: 10px;
    width: inherit;
    display: flex;
    flex-direction: column;
    max-height: 210px;
    overflow-y: scroll;
}

.result {
    padding: 10px;
}

.result:hover {
    color: white;
    cursor: pointer;
    background-color: rgb(179, 189, 206);
}

@media screen and (min-width: 768px) {
    .search-results {
        width: 80.5%;
    }
}