.week {
    color: #8590a5;
    font-weight: 600;
    width: 80%;
    background: linear-gradient(6deg, rgba(22,36,65,1) 0%, rgba(20,28,48,1) 100%);
    border-radius: 10px;
    border: 1px solid rgb(74, 80, 102);
    padding: 15px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.daily {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.daily > * {
    height: 75px;
    padding: 10px 20px;
    justify-content: center;
    align-self: center;
}

.icon-container {
    padding: 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.week .icon-container {
    width: auto;
    padding: 0 20px;
}

.week .icon-container > img {
    width: 50px;
    height: 50px;
}

.week-temp {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 0 0;
    width: 120px;
    font-size: 1.2rem;
    height: fit-content;
}

.week-temp > p {
    display: flex;
    width: 40px;
}

.temp-hi {
    color: #a9b7d3;
    justify-content: flex-end;
    border-right: 1px solid #8590a5;
    padding-right: 10px;
    margin-right: 10px;
}

.week-day {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .week .week-day {
        font-size: 1.1rem;
    }

}

