@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
    url('../assets/font/Manrope-ExtraLight.ttf') format('truetype')
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Manrope', serif;
}

body {
    background-color: #212A3E;
    min-width: 360px;
}

.App {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: inherit;
    width: 100%;
}

.current-day {
    display: flex;
    flex-direction: column;
    color: #8590a5;
    font-weight: 600;
    width: 80%;
    margin: 20px 0;
    padding: 20px;
    background: linear-gradient(6deg, rgba(22,36,65,1) 0%, rgba(20,28,48,1) 100%);
    border-radius: 10px;
    border: 1px solid rgb(74, 80, 102);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.city-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 20px 0;
}

.city-name {
    font-size: 2rem;
}

.city-state {
    font-size: 0.9rem;
}

.calendar {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.current-weather {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#current-temp {
    font-size: 2.5rem;
    margin-left: 20px;
}

.current-weather > * {
    padding: 5px 0;
}

.current-day .icon-container > img {
    width: 100px;
    height: 100px;
}

.footer {
    font-weight: 600;
    color: #8590a5;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .icons {
    display: flex;
    padding: 15px 0 20px 0;
}

.footer a {
    color: #8590a5;
    text-decoration: none;
}

.icons > * {
    margin: 0 15px;
}

@media screen and (min-width: 768px) {
    .current-day, .week {
        width: 60%;
        font-size: 1.3rem;
    }
}

@media screen and (min-width: 1200px) {
    .info {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        width: 80%;
    }

    .info > *, .week p {
        font-size: 1rem;
    }

    .current-day {
        justify-content: center;
        height: 557px;
    }

    .current-day, .week {
        width: 400px;
        margin: 20px 10px;
    }

    .App {
        position: relative;
    }

}