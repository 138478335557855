.search-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: linear-gradient(6deg, rgba(22,36,65,1) 0%, rgba(20,28,48,1) 100%);
    border-radius: 10px;
    border: 1px solid rgb(74, 80, 102);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
}

.searchbar form {
    display: flex;
    height: 100%;
}

.searchbar input {
    width: 100%;
    background-color: #394867;
    color: #8590a5;
    font-weight: 600;
    font-size: 1rem;
    padding: 0 10px;
    margin-right: 10px;
    height: 35px;
    border-radius: 10px;
    border: none;
}

.searchbar input::placeholder {
    color: #8590a5;
    font-size: 1rem;
    font-weight: 600;
}

.searchbar button {
    background-color: #20365f;
    float: right;
    width: 40px;
    height: 35px;
    border-radius: 10px;
    border: none;
}

@media screen and (min-width: 768px) {
    .search-container {
        width: 60%;
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 1200px) {
    .search-container {
        width: 820px;
    }
}